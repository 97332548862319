import { DBFactory } from "~/classes/database/db_factory";
import { BaseModel } from "../base.model";

export type StudentCriteriaStatistics = StudentCriteriaStatistic[];

type StudentCriteriaStatisticConstructorParams = {
  classroomId: string;
  studentId: string;
  checklistCriteriaId: string;
  teacherId: string;
  mapAssignmentIdScore: {
    [key: string]: {
      maxPoints: number;
      earnedPoints: number;
    };
  };
};

export class StudentCriteriaStatistic extends BaseModel {
  classroomId: string;
  studentId: string;
  checklistCriteriaId: string;
  teacherId: string;
  mapAssignmentIdScore: {
    [key: string]: {
      maxPoints: number;
      earnedPoints: number;
    };
  };

  constructor(params: StudentCriteriaStatisticConstructorParams) {
    super();
    this.classroomId = params.classroomId;
    this.studentId = params.studentId;
    this.checklistCriteriaId = params.checklistCriteriaId;
    this.teacherId = params.teacherId;
    this.mapAssignmentIdScore = params.mapAssignmentIdScore;
  }

  get databaseConfig(): ModelDatabaseConfig {
    return {
      collection: `assignmentStatistics/default/studentCriteria`,
      path: `/assignmentStatistics/default/studentCriteria/${this.id}`,
    };
  }

  get id(): string {
    return `${this.classroomId}_${this.studentId}_${this.checklistCriteriaId}`;
  }

  static fromMap(data: any): StudentCriteriaStatistic {
    return new StudentCriteriaStatistic(data);
  }

  averageScore(assignmentIds?: string[]): number {
    const assignmentIdKeysToInclude =
      assignmentIds && assignmentIds.length > 0
        ? assignmentIds
        : Object.keys(this.mapAssignmentIdScore);

    let totalEarnedPoints = 0;
    let totalMaxPoints = 0;

    assignmentIdKeysToInclude.forEach((assignmentId) => {
      const assignment = this.mapAssignmentIdScore[assignmentId];
      if (assignment) {
        totalEarnedPoints += assignment.earnedPoints;
        totalMaxPoints += assignment.maxPoints;
      }
    });

    if (totalMaxPoints === 0) return 0;

    return totalEarnedPoints / totalMaxPoints;
  }

  toMap(): ModelDatabaseData {
    return {
      id: this.id,
      classroomId: this.classroomId,
      studentId: this.studentId,
      checklistCriteriaId: this.checklistCriteriaId,
      teacherId: this.teacherId,
      mapAssignmentIdScore: this.mapAssignmentIdScore,
    };
  }

  async save() {
    const db = DBFactory.createDatabase();
    await db.save(this.toMap(), this.databaseConfig);
  }
}
